import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/form-components/Select'))

const SelectMonth = ({ isSubmitting, isSubmitted }) => {
    const months = Array.from({ length: 12 }, (item, i) => {
        return new Date(0, i).toLocaleString('en-GB', { month: 'long' })
    })

    return (
        <Select
            name="dobMonth"
            firstItem="Month"
            data={months}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

SelectMonth.propTypes = {
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool
}

SelectMonth.defaultProps = {
    isSubmitting: false,
    isSubmitted: false
}

export default SelectMonth
